// Action types
export const SET_USER_DATA = 'SET_USER_DATA';
export const UPDATE_USER_IMAGE = 'UPDATE_USER_IMAGE';
export const LOGOUT_USER = 'LOGOUT_USER';

// Action to log in the user
export const setUserData = (userData) => {
  return {
    type: SET_USER_DATA,
    payload: userData,
  };
};

//Action to change profile picture
export const updateUserImage = (newImage) => ({
  type: UPDATE_USER_IMAGE,
  payload: newImage,
});

// Action to log out the user
export const logoutUser = () => {
  return {
    type: LOGOUT_USER,
  };
};
