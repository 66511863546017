import { SET_USER_DATA, UPDATE_USER_IMAGE, LOGOUT_USER } from '../actions/userActions';

const initialState = null; // Initially, there's no user data

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return action.payload; // Return user data directly without nesting

    case UPDATE_USER_IMAGE:
      return {
        ...state,
        profile_image: action.payload, // Update the profile image
      };

    case LOGOUT_USER:
      return null; // Clear user data on logout

    default:
      return state;
  }
};

export default userReducer;