import React, { Suspense } from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';

// Pages
const Landing = React.lazy(() => import('./pages/landing/Landing'));
const About = React.lazy(() => import('./pages/about/About'));
const Services = React.lazy(() => import('./pages/services/Services'));
const Features = React.lazy(() => import('./pages/features/Features'));
const Contact = React.lazy(() => import('./pages/contact/Contact'));
const Foundation = React.lazy(() => import('./pages/foundation/Foundation'));
const FAQs = React.lazy(() => import('./pages/faqs/FAQs'));
const Terms = React.lazy(() => import('./pages/terms/Terms'));
const Privacy = React.lazy(() => import('./pages/privacy/Privacy'));

// Telecom
const Touch = React.lazy(() => import('./pages/telecom/touch/Touch'));
const Alfa = React.lazy(() => import('./pages/telecom/alfa/Alfa'));



const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

const App = () => {

  return (
    <HashRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route exact path="/" name="Landing Page" element={<Landing />} />
          <Route exact path="/about" name="About Page" element={<About />} />
          <Route exact path="/services" name="Services Page" element={<Services />} />
          <Route exact path="/features" name="Features Page" element={<Features />} />
          <Route exact path="/contact" name="Contact Page" element={<Contact />} />
          <Route exact path="/foundation" name="Contact Page" element={<Foundation />} />
          <Route exact path="/faqs" name="FAQs Page" element={<FAQs />} />
          <Route exact path="/terms" name="Terms Page" element={<Terms />} />
          <Route exact path="/policy" name="Privacy Page" element={<Privacy />} />

          {/* Telecom */}
          <Route exact path="/touch" name="Touch Page" element={<Touch />} />
          <Route exact path="/alfa" name="Alfa Page" element={<Alfa />} />

        </Routes>
      </Suspense>
    </HashRouter>
  );
};

export default App;
